<template>
    <v-card>
      <v-card-title>
        Stock
       <v-icon class="ml-2" :color="siteColor" @click="scanStock = !scanStock">mdi-qrcode-scan</v-icon>
        <div class="flex-grow-1"></div>
        <v-text-field v-model="search" label="Search" single-line hide-details clearable></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="stock.length && !scanStock"
        :headers="headers"
        :items="stock"
        :items-per-page="30"
        item-key="id"
        class="elevation-1"
        :search="search"
        sort-by="devEui"
      >
        <template v-slot:body="{items}">
          <tbody>
            <template>
              <tr v-for="(item, i) in items" :key="i">
                <td>{{ item.devEui }}</td>
                <td><v-text-field :color="siteColor" v-model="item.name" hide-details></v-text-field></td>
                <td><v-select :color="siteColor" item-text="email" single-line hide-details :items="userEmails" v-model="item.email" clearable></v-select></td>
                <td><v-select :color="siteColor" item-text="location" single-line hide-details :items="locationNames" v-model="item.location" clearable></v-select></td>
                <td>{{ item.model === "LHT65" ? "standard" : item.model === "D20LB" ? "waterproof" : item.model === "LTC2HT" ? "high temp" : item.model === "LTC2FSA" ? "food safety" : item.model }}</td>
                <td><v-select item-text="name" single-line hide-details :items="deviceProfiles" v-model="item.profile"></v-select></td>
                <td><v-btn :loading="loading && i == loadingIndex" :color="siteColor" dark @click="addToDash(item,i)">Add to Dashboard</v-btn></td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
      <qrcode-stream v-else-if="scanStock" @decode="decode"></qrcode-stream>
    </v-card>
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  export default {
    name: "stock",
    data() {
      return {
        search: "",
        scanStock: false,
        owners: [],
        loadingIndex: -1,
        loading: false,
        deviceProfiles: ["cooler", "freezer", "smoker"],
        headers: [
        { text: "DevEUI", value: "devEui", width: "15%" },
          { text: "Name", value: "name", width: "20%" },
          { text: "Owner", value: "email" },
          { text: "Location", value: "location" },
          { text: "Model", value: "model" },
          { text: "Profile", value: "profile", width: "10%" },
          { text: "", value: "", sortable: false, },
        ]
      };
    },
    async created() {
      await Promise.all([this.$store.dispatch("sensor/getDevices"), this.$store.dispatch("user/getAllUsers"), this.$store.dispatch("sensor/getSensorStock"), this.$store.dispatch("location/allLocations")])
    },
    computed: {
      ...mapState("user", ["user", "users"]),
      ...mapState("sensor", ["stock", "devices"]),
      ...mapState("location", ["locations"]),
      ...mapGetters("location", ["locationNames"]),
      ...mapGetters("user", ["isSuper", "userEmails"])
    },
    methods: {
      decode(val) {
        this.search = val
        this.scanStock = false
      },
      async addToDash(item, i) {
        if (!item.name) return
        this.loadingIndex = i
        this.loading = true
        await this.$store.dispatch("sensor/moveStockToDash", {
          devEui: item.devEui,
          name: item.name,
          email: item.email,
          sensorLocationId: item.location ? this.checkLocationId(item.location) : undefined,
          profile: item.profile,
          sensorDeviceId: this.checkDeviceId(item.model),
          sensorUserId: item.email ? this.checkUserId(item.email) : undefined
        })
        this.loading = false
        this.search = ""
      },
      checkDeviceId(model = "LHT65") {
        return this.devices.find(dev => dev.model === model).id
      },
      checkUserId(email) {
        return this.users.find(user => user.email === email).id
      },
      checkLocationId(location) {
        return this.locations.find(loc => loc.name === location).id
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .add-btn {
    border: 1px solid #e2e2e2;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #0095da;
    color: #fff;
  }
  table {
  .v-text-field {
    border-color: #ffffff1f;
    padding-top: 0px;
    margin-top: 0px;
    font-size: 14px;
  }
}
  </style>